// Required dependencies

import 'jquery';
import 'bootstrap';
import 'nette.ajax.js';

import LiveFormValidation from 'live-form-validation-es6';

window.LiveForm = LiveFormValidation.LiveForm;
window.Nette = LiveFormValidation.Nette;

import '../sass/main.sass';

import '@fortawesome/fontawesome-free/scss/fontawesome.scss';
import '@fortawesome/fontawesome-free/scss/solid.scss';
import '@fortawesome/fontawesome-free/scss/brands.scss';
import '@fortawesome/fontawesome-free/scss/regular.scss';

import Swiper, { Autoplay } from "swiper";
// swiper bundle styles
import "swiper/swiper-bundle.css";
// modules styles
import "swiper/modules/navigation/navigation.min.css";

import {gsap} from 'gsap';
import {TextPlugin} from 'gsap/TextPlugin';
import {CSSRulePlugin} from 'gsap/CSSRulePlugin';
import {ScrollTrigger} from 'gsap/ScrollTrigger';

gsap.registerPlugin(TextPlugin, CSSRulePlugin, ScrollTrigger);

$(function() {

  $.nette.init();
  window.Nette.init();

  window.LiveForm.options = {
    messageErrorPrefix: "",
    messageErrorClass: "invalid-feedback",
    controlErrorClass: "is-invalid",
    controlValidClass: "is-valid",
    messageTag: "div",
    showValid: false,
    showAllErrors: true,
    wait: 500,
    focusScreenOffsetY: true,
    // showMessageClassOnParent: "form-group",
    // messageParentClass: true,
  };

  let swiper = new Swiper(".mySwiper", {
    modules: [Autoplay],
    spaceBetween: 50,
    loop: true,
    autoplay: {
      delay: 3000,
    },
  });

  if (document.querySelector('.homepage')) {
    let intro = gsap.timeline();
    intro.to('.homepage .logo', {opacity: 1, delay: .5});
    intro.to('.homepage .nav-item', {
      opacity: 1, stagger: {
        each: 0.2,
      },
    }, '<');
  }

  if (document.querySelector('.beers')) {
    let beers = gsap.timeline();
    beers.to('.beers-item',{
      duration: 0.2,
      opacity: .7,
      stagger: {each: 0.2}
    });
  }

});